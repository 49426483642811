import React from 'react';

import Layout from '../components/layout';
import Link from '../components/link';
import Seo from '../components/seo';

export default () => (
  <Layout>
    <Seo title="404 nenalezeno | HTMLFACTORY" />

    <div
      className="text-center py-2 py-md-4 bg-light d-flex flex-column flex-grow-1 justify-content-center"
      style={{ minHeight: "50vh" }}
    >
      <div className="container">
        <h1 className="mb-4">404 - Stránka nenalezená</h1>
        <Link to="/" className="btn btn-dark btn-lg">
          Zpět na objednávku
        </Link>
      </div>
    </div>
  </Layout>
)
